import React from 'react';
import logo from './logo.svg';
import './App.css';
import './Common.scss';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="fontBlack">
          Hello there, it's Dmytro 👋
        </h1>

        <p>          
          I’m from Kharkiv 🇺🇦
          <br />
          Currently in Lisbon 🇵🇹
        </p>
        <br />
        <a href="https://twitter.com/dmytrotkk">Twitter</a>
        <a href="https://www.instagram.com/dmytrotk">Instagram</a>
      </header>
    </div>
  );
}

export default App;
